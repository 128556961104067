import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-244ebe64")
const _hoisted_1 = {
  key: 0,
  class: "install"
}
const _hoisted_2 = { class: "ion-padding" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!_ctx.loader)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("form", null, [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Logo),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "floating" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('Shop')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.shopOrigin,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.shopOrigin = $event)),
                            name: "shopOrigin",
                            type: "text",
                            required: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_ion_button, {
                      type: "submit",
                      expand: "block",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.install(_ctx.shopOrigin)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('Install')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}